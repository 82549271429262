html, body {
    /*background: url('../public/pattern.png');*/
    background: rgba(11, 11, 11, 0.10);
    height: 100%;
}

.roboto-thin {
    font-family: "Roboto", serif;
    font-weight: 100;
    font-style: normal;
}

.roboto-regular {
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: normal;
}

.roboto-bold {
    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: normal;
}

.roboto-thin-italic {
    font-family: "Roboto", serif;
    font-weight: 100;
    font-style: italic;
}

.roboto-regular-italic {
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: italic;
}

.roboto-bold-italic {
    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: italic;
}

.App {
    font-size: 1.1em;
    line-height: 1.6em;
    text-indent: 20px;
    text-align: justify;
    text-justify: inter-word;
    /*text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;*/
}
